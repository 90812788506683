<template>
  <DefaultLayout>
    <v-layout>
      <v-flex>
        <v-card elevation="0">
          <v-card-title>
            User info:
          </v-card-title>
          <v-card-text>
            <ul>
              <li>Set session timeout to 60</li>
              <li>Added some action logs. Not yet happy with them</li>
              <li>Removed default login name</li>
              <li>Intercet permission missing and redirect to login page</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-flex>
      <pre>{{user}}</pre>
    </v-layout>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";
import { mapGetters } from "vuex";

export default {
  name: "home",
  computed: {
    ...mapGetters([ "user" ])
  },
  components: {
    DefaultLayout
  }
};
</script>
